.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.fl-r {
  float: right;
}

.fl-l {
  float: left;
}

.fw-bold {
  font-weight: bold;;
}

.danger-btn {
  background-color: #f44336;
}